import { useQuery } from "@tanstack/react-query";

const useGetRequest = (
  queryName: string | string[] | any,
  axiosHook: any,
  options?: any
) => {
  const queryData = useQuery(queryName, () => axiosHook(), options);
  return queryData;
};

export default useGetRequest;
