import Axios from "axios";
import { getCookie } from "./cookieUtils";

const { REACT_APP_BASE_URL } = process.env;

const baseURL = REACT_APP_BASE_URL;

export const $axiosProtected = () => {
  const authToken = getCookie("authToken");
  const protectedHeaders = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${authToken}`,
  };
  const instance = Axios.create({
    headers: { ...protectedHeaders },
    timeout: 1000000,
    baseURL: baseURL,
  });

  return instance;
};
