import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";

import Course from "./Course";
import useGetRequest from "../../../queries/getRequest.query";
import useMutateRequest from "../../../queries/getMutation.query";
import {
  getAllCourses,
  searchCourseByTitle,
} from "../../../services/Webview.services";

const CoursePage = ({ pageTitle }: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit] = useState(5);
  const [filterData, setFilterData]: any = useState({
    chwGrade: "",
    courseLanguage: "",
    courseCategory: "",
  });

  const {
    data: coursesList,
    isLoading,
    isFetching,
    refetch,
    isError: isGetCoursesError,
    error,
  }: any = useGetRequest(
    ["getAllCourses", pageNumber],
    async () =>
      await getAllCourses({
        page: pageNumber,
        limit: pageLimit,
        chwGrade: filterData?.chwGrade,
        courseLanguage: filterData?.courseLanguage,
        courseCategory: filterData?.courseCategory,
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );
  const totalCoursesEntites = coursesList?.data?.results;
  const courseData = coursesList?.data?.data?.courses;
  const totalCoursesPages = Math.ceil(totalCoursesEntites / pageLimit);
  const courseErrorMsg = error?.response?.data?.message;

  // Search
  const [searchData, setSearchData] = useState([]);
  const [searchPageNumber] = useState(1);
  const [searchFormProps, setSearchFormProps] = useState("");
  const {
    data,
    mutate,
    isError: isSearchError,
    error: ErrorMsg,
    isLoading: searchLoader,
    reset,
  }: any = useMutateRequest((data: string) => searchCourseByTitle(data));

  const totalSearchEntites = data?.data?.results;
  const totalSearchCourses = Math.ceil(totalSearchEntites / pageLimit);
  const totalPages =
    searchData?.length > 0 ? totalSearchCourses : totalCoursesPages;

  const searchErrorMsg = ErrorMsg?.response?.data?.message;
  const searchSchema = Yup.object().shape({
    courseTitle: Yup.string()
      .min(3, "Minimum 3 Letters Required")
      .label("Search"),
  });

  const onSearchSubmit = (values: any, onSubmitProps: any) => {
    try {
      setSearchFormProps(onSubmitProps);
      const payload = {
        courseTitle: values.courseTitle,
        limit: pageLimit,
        page: searchPageNumber,
      };

      mutate(payload, {
        onSuccess: (data: any) => {
          const searchTerm = data?.data?.data?.courses;
          setSearchData(searchTerm);
          // onSubmitProps.resetForm();
        },
        onError: (error: any) => {
          console.log(error);
          setSearchData([]);
          // onSubmitProps.resetForm();
        },
      });
    } catch (error) {
      console.log(error);
      // onSubmitProps.resetForm();
      setSearchData([]);
    }
  };

  let allCourses;
  if (searchData?.length > 0) {
    allCourses = searchData;
  } else {
    allCourses = courseData;
  }
  const clearData = (searchFormProps: any) => {
    if (searchFormProps) {
      searchFormProps.resetForm();
      setSearchData([]);
      reset();
      refetch();
    }

    const payload = {
      chwGrade: "",
      courseLanguage: "",
      courseCategory: "",
    };
    setFilterData(payload);
  };
  return (
    <div className="flex flex-col align-middle justify-center w-full">
      <div className="flex align-middle h-12">
        <h1 className="text-3xl font-semibold mr-4">{pageTitle}</h1>
        <i
          onClick={() => clearData(searchFormProps)}
          className="pi pi-refresh cursor-pointer mt-3"
          style={{ fontSize: "1em" }}
        ></i>
      </div>
      {isLoading || isFetching || searchLoader === true ? (
        <div className="flex justify-center h-44">
          <ProgressSpinner />
        </div>
      ) : isGetCoursesError || isSearchError === true ? (
        <>
          <div
            style={{
              height: "650px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bg-white"
          >
            <h1 className="text-red-500 text-2xl">
              {isSearchError
                ? searchErrorMsg
                : isGetCoursesError
                ? "No Data Available"
                : "Something went wrong!!"}
            </h1>
          </div>
        </>
      ) : (
        <>
          <div className="w-full">
            {/* <Searchbar /> */}
            <div className="py-2">
              <Formik
                initialValues={{
                  courseTitle: "",
                }}
                validationSchema={searchSchema}
                onSubmit={onSearchSubmit}
              >
                {(formProps: any) => (
                  <Form>
                    <div className="flex align-middle justify-center">
                      <Field
                        type="search"
                        name="courseTitle"
                        placeholder="Search Course"
                        className="w-full h-10 p-5 align-middle"
                      />
                      <button
                        disabled={
                          formProps.isSubmitting ||
                          !(formProps.isValid && formProps.dirty)
                        }
                        type="submit"
                        style={{
                          cursor:
                            formProps.isSubmitting ||
                            !(formProps.isValid && formProps.dirty)
                              ? "not-allowed"
                              : "pointer",
                        }}
                        className="ml-3 bg-blue-600 disabled:bg-blue-300 rounded p-2 text-white w-20"
                      >
                        Search
                      </button>
                    </div>
                    <div className="w-100 flex flex-col align-middle justify-center text-red-800 mt-1">
                      <ErrorMessage name="courseTitle" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {/* Filter */}
          </div>
          <div className="mt-5 flex flex-row flex-wrap">
            {allCourses?.map((course: any) => {
              return (
                <>
                  <Link to={`/course/${course._id}`} className="">
                    <Course
                      id={course._id}
                      title={course?.courseTitle}
                      courseImage={course.courseImage}
                      language={course.courseLanguage}
                      description={course.courseDescription}
                      duration={course.courseDuration}
                      courseFocus={course.coursePrimaryFocus}
                      chwLevel={course.chwGrade}
                    />
                  </Link>
                </>
              );
            })}
          </div>

          {/* Pagination */}
          <div className="mt-10 md:flex md:flex-wrap md:justify-between sm:flex sm:flex-wrap sm:justify-center sm:align-middle">
            <div className="fs-6 font-bold text-gray-700 me-5 mb-5 text-center">
              {isSearchError || isGetCoursesError
                ? `No Record Found`
                : searchData?.length > 0
                ? // Search Based
                  `Showing ${pageNumber} to ${totalPages} of ${totalSearchEntites} total Entries`
                : //  Get API Based
                  `Showing ${pageNumber} to ${totalCoursesPages} of ${totalCoursesEntites} total Entries`}
            </div>

            {isSearchError || isGetCoursesError === true ? (
              <span
                className="bg-blue-600 "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  width: "30px",
                  height: "35px",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                <h3
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    textAlign: "center",
                    marginBottom: "0px",
                  }}
                >
                  0
                </h3>
              </span>
            ) : (
              <div className="flex align-middle justify-center">
                {/* Previous Page */}
                <span className="mr-2">
                  <button
                    className="bg-blue-600 disabled:bg-blue-300 rounded p-2 text-white w-10"
                    onClick={() =>
                      setPageNumber(
                        (prevPageNumber: number) => prevPageNumber - 1
                      )
                    }
                    disabled={pageNumber === 1}
                    style={{
                      border: "none",
                      cursor: pageNumber === 1 ? "not-allowed" : "pointer",
                    }}
                  >
                    &lt;&lt;
                  </button>
                </span>
                {/* Page Number */}
                <span
                  className="bg-blue-600"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    width: "30px",
                    height: "40px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    {pageNumber}
                  </h3>
                </span>
                {/* Next Page */}
                <span className="ml-2">
                  <button
                    className="bg-blue-600 disabled:bg-blue-300 rounded p-2 text-white w-10"
                    onClick={() =>
                      setPageNumber(
                        (prevPageNumber: number) => prevPageNumber + 1
                      )
                    }
                    disabled={pageNumber === totalPages}
                    style={{
                      border: "none",
                      cursor:
                        pageNumber === totalPages ? "not-allowed" : "pointer",
                    }}
                  >
                    &gt;&gt;
                  </button>
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CoursePage;
