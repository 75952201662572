import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressSpinner } from "primereact/progressspinner";

import { getCourseContentById } from "../../../services/Webview.services";
import useGetRequest from "../../../queries/getRequest.query";
import ModalUI from "../../components/ModalUI";

const CourseContent = () => {
  const { id }: any = useParams();

  // Get Course content
  const {
    data: courseContent,
    isLoading,
    isFetching,
    refetch: courseContentRefetch,
    error: courseContentError,
    isError,
  }: any = useGetRequest(
    ["getCourseContentById"],
    async () =>
      await getCourseContentById({
        courseId: String(id),
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );
  const courseContentSections = courseContent?.data?.data?.courseContent;
  const isContentExisting = courseContent?.data?.data?.isContentExist;

  const totalSections = courseContentSections?.sections?.length;
  const totalLessons = courseContentSections?.sections?.reduce(function (
    previousValue: any,
    currentValue: any = 0
  ) {
    return previousValue?.lessons?.length + currentValue?.lessons?.length;
  });
  const totalLesson = courseContentSections?.sections?.length;

  useEffect(() => {
    courseContentRefetch();
  }, [id]);

  return (
    <>
      <section className="my-5">
        {isLoading || isFetching ? (
          <div
            style={{
              height: "650px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressSpinner />
          </div>
        ) : isError === true || isContentExisting !== true ? (
          <>
            <section
              style={{ height: "150px", alignItems: "center" }}
              className="mt-5 flex justify-center"
            >
              <h3 className="text-center font-bold text-red-600">
                There is no course content
              </h3>
            </section>
          </>
        ) : (
          <>
            <section className="flex flex-col my-5">
              <span className="text-lg font-medium">Course Content</span>
              <span className="mt-3 text-sm font-medium">
                <>
                  {totalSections} topics -{" "}
                  {totalSections > 1 ? totalLessons : totalLesson} lessons
                </>
              </span>
            </section>

            <div className="accordion-demo">
              {courseContentSections?.sections?.map(
                (section: any, index: any) => {
                  return (
                    <>
                      <Accordion multiple className="mb-5">
                        <AccordionTab
                          headerTemplate={
                            <div className="flex">
                              <span>
                                {index + 1} {section?.sectionName} ({" "}
                                {section?.lessons?.length} )
                              </span>
                            </div>
                          }
                        >
                          <ModalUI resources={section?.lessons} />
                        </AccordionTab>
                      </Accordion>
                    </>
                  );
                }
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default CourseContent;
