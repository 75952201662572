import { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPlayer from "react-player/youtube";
import useIntersectionObserver from "../../hooks/useIntersectionObservable.hook";
import { Sidebar } from "primereact/sidebar";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const observerConfig = {
  threshold: 0,
};
// Page with observer component
function PageWithObserver({
  pageNumber,
  setPageVisibility,
  ...otherProps
}: any) {
  const [page, setPage] = useState(null);

  const onIntersectionChange = useCallback(
    ([entry]: any) => {
      setPageVisibility(pageNumber, entry.isIntersecting);
    },
    [pageNumber, setPageVisibility]
  );

  useIntersectionObserver(page, observerConfig, onIntersectionChange);

  return (
    <Page
      width={500}
      scale={1.8}
      canvasRef={setPage}
      pageNumber={pageNumber}
      {...otherProps}
    />
  );
}

// const ModalUI = ({ onClose, userData, styles }: any) => {
const ModalUI = ({ resources }: any) => {
  const [numPages, setNumPages] = useState(null);
  const [visiblePages, setVisiblePages] = useState({});

  const [visibleBottom, setVisibleBottom] = useState(false);
  const [selectedData, setSelectedData]: any = useState(null);
  const onClick = (resource: any) => {
    setSelectedData(resource);
    setVisibleBottom(true);
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const setPageVisibility = useCallback(
    (pageNumber: any, isIntersecting: any) => {
      setVisiblePages((prevVisiblePages) => ({
        ...prevVisiblePages,
        [pageNumber]: isIntersecting,
      }));
    },
    []
  );
  return (
    <div>
      {resources.map((resource: any, index: any) => {
        return (
          <>
            <span className="flex p-1">
              <span className="mr-2">{resource?.lessonNumber}</span>
              {resource?.lessonType === "Video" ? (
                <i className="pi pi-youtube" style={{ fontSize: "1.5em" }}></i>
              ) : (
                <i className="pi pi-file-pdf" style={{ fontSize: "1.5em" }}></i>
              )}

              <button onClick={() => onClick(resource)} className="ml-1">
                {resource?.lessonName}
              </button>
            </span>
          </>
        );
      })}

      {/* User Data */}
      <>
        <Sidebar
          visible={visibleBottom}
          position="bottom"
          onHide={() => setVisibleBottom(false)}
          fullScreen
          maskClassName="p-50"
        >
          {selectedData &&
            (selectedData?.lessonType === "Video" ? (
              <div
                style={{
                  width: "100%",
                  height: "550px",
                  padding: "10px",
                }}
              >
                <ReactPlayer
                  url={selectedData?.videoLink}
                  controls
                  height="80vh"
                  width="90vw"
                />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  // height: "550px",
                  height: "100%",
                  backgroundColor: "rgba(109, 109, 109, 0.5)",
                  overflowY: "scroll",
                  padding: "5px",
                }}
              >
                <Document
                  file={selectedData?.documentValue}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <PageWithObserver
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      setPageVisibility={setPageVisibility}
                    />
                  ))}
                </Document>
              </div>
            ))}
        </Sidebar>
      </>
    </div>
  );
};

export default ModalUI;
