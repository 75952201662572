import { $axiosProtected } from "../utils/axiosUtils";

// Get List of all Courses
export const getAllCourses = async (payload: any) => {
  return await $axiosProtected().get(
    `/training-cms/course/web-view/get/all?page=${payload.page}&limit=${payload.limit}&courseLanguage=${payload.courseLanguage}&chwGrade=${payload.chwGrade}&courseCategory=${payload.courseCategory}`
  );
};

// Search Individual Course Details By Title
export const searchCourseByTitle = async (payload: any) => {
  return await $axiosProtected().post(
    `/training-cms/course/web-view/search?page=${payload.page}&limit=${payload.limit}`,
    payload
  );
};

// Get Individual Course Details By Id
export const getCourseById = async (payload: any) => {
  return await $axiosProtected().get(
    `/training-cms/course/get?courseId=${payload.courseId}`
  );
};

// Get Course Content By Id
export const getCourseContentById = async (payload: any) => {
  return await $axiosProtected().get(
    `/training-cms/course/content/get?courseId=${payload.courseId}`
  );
};
