import CoursePage from "./courses/CoursePage";

const HomePage = () => {
  return (
    <div className="p-5">
      <CoursePage pageTitle="List of courses" />
    </div>
  );
};

export default HomePage;
