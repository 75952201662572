import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import SplashScreen from "./components/SplashScreen";
import AppRoutes from "./routing/AppRoutes";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className="bg-transparent">
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<SplashScreen />}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </div>
  );
};

export default App;
