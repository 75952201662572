import CourseHeader from "./CourseHeader";
import CourseContent from "./CourseContent";

const CourseLandingPage = () => {
  return (
    <div className="p-5">
      <>
        <CourseHeader />

        <CourseContent />
      </>
    </div>
  );
};

export default CourseLandingPage;
