import { useMutation } from "@tanstack/react-query";

const useMutateRequest = (axiosHook: any, options?: any) => {
  const mutationData = useMutation(
    (payload: any) => axiosHook(payload),
    options
  );

  return mutationData;
};

export default useMutateRequest;
