/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components .
 */

import { getCookie, setCookie } from "../../utils/cookieUtils";
import NoAuth from "../components/NoAuth";

import PublicRoutes from "./PublicRoutes";

const AppRoutes = () => {
  // alert(getCookie("authToken"));

  let isAuthorised = getCookie("authToken");

  return (
    <>
      {isAuthorised ? (
        <>
          <PublicRoutes />
        </>
      ) : (
        <>
          <NoAuth />
        </>
      )}
    </>
  );
};

export default AppRoutes;
