import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { useParams } from "react-router-dom";

import { toAbsoluteUrl } from "../../../utils/toAbsoluteUrl";
import { getCourseById } from "../../../services/Webview.services";

const CourseHeader = () => {
  const { id }: any = useParams();
  // Get course by Id
  const {
    data: courseData,
    isLoading,
    isFetching,
    refetch,
    isError,
    error,
  }: any = useQuery(
    ["getCourseById"],
    async () =>
      await getCourseById({
        courseId: String(id),
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );
  const course = courseData?.data?.data?.courses[0];
  const courseErrorMsg = error?.response?.data?.message;

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <>
      {isLoading || isFetching ? (
        <div
          style={{
            height: "650px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <section className="flex flex-col w-full bg-slate-800">
            <div className="w-6/6">
              <img
                className="w-full p-2 h-56 object-fill"
                src={
                  course?.courseImage !== ""
                    ? course?.courseImage
                    : toAbsoluteUrl("/media/defaults/courses/vegetables.jpeg")
                }
                alt={course?.courseTitle}
              />
            </div>
            <div className="p-5 flex flex-col align-middle justify-center text-white">
              <h1 className="text-3xl mb-2 font-bold tracking-wide">
                {course?.courseTitle}
              </h1>
              <p className="mb-3 text-md font-bold">
                {course?.courseDescription}
              </p>
              <div className="flex mb-3 font-normal justify-between">
                <span className="flex align-middle">
                  <i
                    className="pi pi-clock mt-1"
                    style={{ fontSize: "1em" }}
                  ></i>
                  <p className="ml-1">{course?.courseDuration}</p>
                </span>
                <span className="flex align-middle">
                  <i
                    className="pi pi-exclamation-circle mt-1"
                    style={{ fontSize: "1em" }}
                  ></i>
                  <p className="ml-1">{course?.courseLanguage}</p>
                </span>
              </div>
              <p className="flex flex-wrap">
                <i className="pi pi-user mt-1" style={{ fontSize: "1em" }}></i>
                {course?.chwGrade?.map((grade: any) => {
                  return (
                    <p className="ml-2 mr-2 font-normal">{grade?.gradeName}</p>
                  );
                })}
              </p>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default CourseHeader;
