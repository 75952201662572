const NoAuth = () => {
  return (
    <div
      style={{
        height: "600px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 className="font-medium text-3xl text-red-600">
        Authorization Failed
      </h1>
    </div>
  );
};

export default NoAuth;
