import { toAbsoluteUrl } from "../../../utils/toAbsoluteUrl";
import { Card } from "primereact/card";

const Course = ({
  courseImage,
  id,
  title,
  description,
  duration,
  chwLevel,
  language,
  courseFocus,
}: any) => {
  const header = (
    <img
      alt="Card"
      src={toAbsoluteUrl(courseImage)}
      className="object-fit object-fill w-48 h-48"
      // className="object-fit lg:w-25 lg:h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg sm:w-full sm:h-28 sm:p-0"
    />
  );

  return (
    <>
      <div className="mb-5 md:mr-5">
        <Card
          header={header}
          subTitle={<p className="font-semibold text-black">{description}</p>}
          title={<p className="font-bold text-black text-3xl">{title}</p>}
        >
          <hr className="w-full h-1" />
          <p className="mt-2 font-medium text-gray-500">{courseFocus}</p>
          <div className="flex justify-between leading-normal">
            <p className="font-medium text-gray-500">{duration}</p>
            <p className="font-medium text-gray-500">{language}</p>
          </div>
          <div className="flex flex-wrap">
            {chwLevel?.map((chw: any) => {
              return (
                <p className="font-medium flex flex-col rounded mr-2 mt-2 text-gray-500">
                  {chw?.gradeName}
                </p>
              );
            })}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Course;
